<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!-- ADD DEPARTMENT FORM  -->
    <div class="vx-row" v-if="loginUserRole === 'Admin'">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Company">
          <form action>
            <div class="row">
              <div class="col-12 col-md-6 mb-3">
                <h6>Name*</h6>

                <vs-input
                  v-validate="'required|alpha_spaces'"
                  name="Company Name"
                  v-model="form.company_name"
                  class="w-100"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-xs"
                    v-show="errors.has('Company Name')"
                    >{{ errors.first("Company Name") }}</span
                  >
                </p>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <h6>Email*</h6>
                <vs-input
                  v-validate="'required|email'"
                  name="Company Email"
                  v-model="form.company_email"
                  class="w-100"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('Company Email')"
                    >{{ errors.first("Company Email") }}</span
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 mb-3">
                <h6>Phone*</h6>
                <vs-input
                  v-validate="'required|numeric|max:14'"
                  name="Company Phone"
                  v-model="form.company_phone"
                  class="w-100"
                  type="number"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('Company Phone')"
                    >{{ errors.first("Company Phone") }}</span
                  >
                </p>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <h6>Website*</h6>
                <vs-input
                  v-validate="'required|url'"
                  name="Company Website"
                  v-model="form.company_url"
                  class="w-100"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('Company Website')"
                    >{{ errors.first("Company Website") }}</span
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class=" col-12 col-md-6 mb-3   ">
                <h6>Address</h6>
                <vs-textarea
                  name="Company Address"
                  v-model="company_address"
                  class="w-full"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('Company Address')"
                    >{{ errors.first("Company Address") }}</span
                  >
                </p>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <h6>Description</h6>
                <vs-textarea
                  name="Company Description"
                  v-model="company_description"
                  class="w-full"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('Company description')"
                    >{{ errors.first("Company Description") }}</span
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 mb-3">
                <h6>Country*</h6>
                <multiselect
                  v-model="value"
                  track-by="country_id"
                  label="country_name"
                  @input="
                    ({ country_id }) => (this.form.country_id = country_id)
                  "
                  :options="countries"
                  name="Country"
                  :searchable="true"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                  :clear-on-select="false"
                  :preselect-first="true"
                  open-direction="bottom"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.country_name
                  }}</template>
                </multiselect>
                <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('Country')"
                    >{{ errors.first("Country") }}</span
                  >
                </p>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <h6>Logo</h6>
                <vue-dropzone
                  @vdropzone-files-added="vdropzoneFilesAdded"
                  @vdropzone-success="vdropzoneSuccess"
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                ></vue-dropzone>
                <vs-button
                  v-if="imageUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="clearImage()"
                  class="feather icon-trash mt-2"
                ></vs-button>
              </div>
            </div>

            <div class="row">
              <div class=" mx-auto text-center mt-5">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3 mb-2"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class="mb-2"
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import CompanyService from "@/services/companyService.js";
import CountryService from "@/services/countryService.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const VxTour = () => import("@/components/VxTour.vue");
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
export default {
  components: {
    AgGridVue,
    VxTour,
    vueDropzone: vue2Dropzone,
    DatetimePickerStartEnd,
  },
  beforeMount() {
    this.loginUserRole = window.localStorage.getItem("UserRole");

    this.dropzoneOptions["url"] =
      BASEURL + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptions["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      excelurl: BASEURL,
      loginUserRole: "",
      dropzoneOptions: {
        url:
          BASEURL +
          "/v2/multipleUploads?organization_id=" +
          this.organization_id,
        acceptedFiles: "image/*",
        ...constant.DEFAULT_IMAGE,
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
      },
      imageUpload: {
        image_url: "",
        deleteButtonStatus: false,
      },
      switch1: true,
      submitStatus: true,
      User: "Select User",
      company_description: "",
      company_address: "",
      company_logo: "",
      form: {
        country_id: "",
        company_name: "",
        company_email: "",
        company_phone: "",
        company_active: 1,
        company_url: "",
      },
      countries: [],
      value: [],
      companies: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },

    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(newVal).length;
        // let array = ["name", "name1"];
        // if (
        //   array.length ===
        //   array.filter(
        //     x =>
        //       newVal[x] &&
        //       newVal[x] !== undefined &&
        //       String(newVal[x]).trim().length > 0
        //   ).length
        // ) {
        // } else {
        // }
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    organization_id() {
      return window.localStorage.getItem("OrganizationID");
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onClickSampleDownload: function(URL) {
      window.open(URL, "_blank");
    },
    clearImage: function() {
      console.log("t", this.$refs.myVueDropzone.getAcceptedFiles());
      this.$refs.myVueDropzone.removeAllFiles();
      this.imageUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzone.enable();
    },
    vdropzoneFilesAdded: function() {
      console.log("vdropzoneFilesAdded");
      // this.$refs.myVueDropzone.disable();
    },
    vdropzoneSuccess: function(file, response) {
      console.log("response 1:", response);
      this.company_logo = response.data.image_path;
      this.imageUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzone.disable();
    },
    vdropzoneError: function(file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submitStatus = false;
          this.$vs.loading();

          let payload = {
            ...this.form,
            company_description: this.company_description,
            company_address: this.company_address,
            company_logo: this.company_logo,
          };
          CompanyService.addCompany(payload)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshTablecomp", data);
                }, 1);
                this.clearForm();
                this.clearImage();
              } else {
                console.log("data :", data);
                this.$vs.loading.close();

                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();

              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    getCompanyList: function() {
      // console.log("RolesService :", RolesService);
      CompanyService.getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.companies = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getAllCountry: function() {
      CountryService.getAllCountry()
        .then((response) => {
          const { data } = response;
          if (data || data.Status == true) {
            this.countries = data.data;
            // if (this.countries.length > 0) {
            //   this.form.country_id = this.countries[0].country_id;
            // }
            console.log("countries", this.countries);
          } else {
          }
        })
        .catch((error) => {
          console.log("error:", error);
        });
    },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.company_description = "";
      this.company_address = "";
      this.$validator.reset();
      this.value = [];
      this.clearImage();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.getCompanyList();
    this.getAllCountry();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
