
<template>
  <div>
    <!-- <label class="switch">
      <input type="checkbox" @click="popupActive=true" :checked="value == '1'" />
      <span class="slider round"></span>
    </label>-->

    <b-form-checkbox
      @click.native="popupActive = true"
      value="1"
      unchecked-value="2"
      v-model="form.company_active"
      switch
      class="table-status-icon"
    ></b-form-checkbox>

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS' + params.data.company_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Company Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Status?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import CompanyService from "@/services/companyService.js";
export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        company_active: "1",
      },
      copy_company_active: null,
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_company_active = this.form.company_active;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalS" + this.params.data.company_id
    );
    //console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function (e, value) {
      this.$vs.loading();
      let id = this.params.data.company_id;
      let payload = {
        company_active: this.form.company_active,
      };
      CompanyService.editCompany(payload, id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    privStatus: function () {
      // console.log("this.value :", this.value);
      // this.value = this.params.value;
      // console.log("this.value1:", this.value);
      // this.$forceUpdate();
      this.popupActive = false;
      this.form.company_active = this.copy_company_active;
    },
  },
});
</script>