<template>
  <div>
    <span class="text-center table-edit-icon" @click="getPreview()">
      <i class="text-center material-icons">remove_red_eye</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalView'+params.data.company_id"
        title="Company Details"
        :active.sync="popupActive"
      >
        <div class="vx-col w-full">
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Name:</p>
                <div class>
                  <h5>{{ companyDetail.company_name }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Email:</p>
                <div class>
                  <h5>{{ companyDetail.company_email }}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Address:</p>
                <div class>
                  <h5>{{ companyDetail.company_address }}</h5>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Country:</p>
                <div class>
                  <h5>{{ companyDetail.country_name }}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Phone:</p>
                <div class>
                  <h5>{{ companyDetail.company_phone }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Description:</p>
                <div class>
                  <h5>{{ companyDetail.company_description }}</h5>
                </div>
              </div>
            </div>
          </div>
          <!-- 
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Address:</p>
                <div class>
                  <h5>{{ companyDetail.company_address }}</h5>
                </div>
              </div>
            </div>
          </div>-->

          <!-- </div> -->

          <!-- <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/4 w-full text-right align-self-center">
              <h5 class="mb-0">Mode of Payment</h5>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <div class>
                <span>card</span>
              </div>
            </div>
          </div>-->
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CompanyService from "@/services/companyService";
export default Vue.extend({
  data() {
    return {
      params: null,
      companyDetail: {},
      popupActive: false,
      //   project_id: ""
    };
  },

  beforeMount: function() {
    this.company_id = this.params.value;
    console.log("this.params", this.params);
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalView" + this.params.data.company_id,
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    getPreview: function() {
      this.popupActive = true;
      CompanyService.getCompany(
        this.company_id,
        String(this.params.data.company_active) === "4"
          ? "archive"
          : undefined,
      )
        .then(response => {
          const { data } = response;
          if (!data.err||data.Status == true ) {
            this.companyDetail = data.data;
          }
        })
        .catch(error => {});
    },
  },
});
</script>

<style>
</style>